<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card-header d-flex align-items-center">
      <h6 class="card-title mb-0 flex-grow-1 fs-16 fw-700">{{ $t('t-personal-detail') }}</h6>
      <div class="flex-shrink-0">
        <b-button :disabled="disabled || (facilityId === null || facilityId === '')" variant="dark" class="waves-effect waves-light w-sm pt-2 pb-2" data-text="追加"
          @click="redirectToCreateAccount">
          <span class="fs-12">{{ $t('t-create') }}</span>
        </b-button>
      </div>
    </div>
    <div v-if="facilityIDChange" class="table-responsive card">
      <TableCommon ref="tableAccount" :columns="columns" :endpoint="ADMIN_API_PATH.ADMIN_USER"
        :query="{ facilityId: facilityIDChange }" @onEdit="redirectToEditAccount" @onDelete="onDeleteAccount" />
    </div>

  </Layout>
</template>

<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { userService } from "@/services/user.service";
import {ADMIN_API_PATH, DEFAULT_PARAMS, ROLES_JP} from "@/constants";
import Swal from "sweetalert2";
import i18n from "@/i18n";
import { mapGetters, useStore } from "vuex";
import TableCommon from "@/components/common/TableCommon.vue";
import { computed } from "vue";
import { ROLES } from "../../../constants";
import {cloneDeep} from "lodash";

export default {
  name: "List Account",
  components: {
    Layout, PageHeader, TableCommon
  },
  setup() {
    const store = useStore();
    const currentUser = computed(() => store.getters['auth/currentUser']);
    const disabled = computed(() => (currentUser.value && currentUser.value.role === ROLES.FACILITY_ACCOUNT) || !currentUser.value);
    const facilityId = computed(() => store.getters['settings/getFacilityId']);

    return {
      ADMIN_API_PATH,
      disabled,
      facilityId
    }
  },
  data() {
    return {
      listAccounts: [],
      showGetMoreButton: false,
      currentPage: DEFAULT_PARAMS.PAGE,
      isUserClickGetMoreButton: false,
      isLoading: false,
      pagination: null,
      params: {
        "page": DEFAULT_PARAMS.PAGE,
        "pageSize": DEFAULT_PARAMS.PAGE_SIZE,
        "facilityId": ''
      },
      title: "ユーザー管理",
      items: [
        {
          text: this.$t('t-home'),
          href: "/"
        },
        {
          text: this.$t('t-user-info'),
          active: true
        }
      ],
      columns: [
        {
          sortable: false,
          label: '',
          type: 'stt'
        },
        {
          key: 'userIdStr',
          sortable: true,
          label: i18n.global.t('t-user-id')
        },
        {
          key: 'role',
          dynamicText: (row) => `${ROLES_JP[row.role] || row.role}`,
          sortable: true,
          label: i18n.global.t('t-user-role')
        },
        {
          key: 'email',
          dynamicText: (row) => `${row.email}`,
          sortable: true,
          label: i18n.global.t('t-email-address')
        },
        {
          key: 'name',
          sortable: true,
          label: i18n.global.t('t-name')
        },
        {
          key: 'createdAt',
          type: "datetime",
          sortable: true,
          label: i18n.global.t('t-registration-date')
        },
        {
          key: 'updatedAt',
          sortable: true,
          type: "datetime",
          label: i18n.global.t('t-last-updated')
        },
        {
          key: 'status',
          type: 'status',
          sortable: true,
          label: i18n.global.t('t-enable-disable')
        },
        {
          key: 'action',
          options: ['edit', 'delete'],
          type: 'action',
          sortable: false,
          label: '',
          isDisabled: () => this.disabled
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      facilityIDChange: 'settings/getFacilityId',
      currentUser: 'auth/currentUser'
    }),
  },

  methods: {
    async handleDeleteAccount(id) {
      try {
        const res = await userService.deleteUser(id);

        if (res) {
          this.listAccounts = this.listAccounts.filter(item => item.id !== id);
          this.listAccounts.forEach((item, index) => {
            delete item.index;
            item['index'] = index + 1;
          });
          return this.listAccounts;
        }
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    onDeleteAccount(value) {
      const { id, name } = value;

      Swal.fire({
        title: `<h2 class='swal2-title' id='swal2-title' style='display: block; font-size: 20px !important;'>${i18n.global.t('deleted_message', { message: name || '' })}</h2>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: i18n.global.t('yes'),
        cancelButtonText: i18n.global.t('cancel'),
      })
        .then(async (result) => {
          if (result.value) {
            try {
              await this.handleDeleteAccount(id);
            } catch (e) {
              let message = e?.response?.data?.message || '';
              await Swal.fire({
                title: "",
                text: message,
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
              });
            } finally {
              this.$refs.tableAccount.refreshTable();
            }
          }
        });
    },

    redirectToEditAccount(id) {
      if (this.currentUser.role === ROLES.FACILITY_MANAGER) {
        this.$router.push({ name: 'edit-account', params: { id: id } });
      } else if (this.currentUser.id === id) {
        this.$router.push('/admin/profile');
      }
    },
    redirectToCreateAccount() {
      if (this.disabled || (this.facilityId === null || this.facilityId === '')) {
        return;
      }

      this.$router.push({ name: 'create-account' });
    },
  },

  mounted() {
    if (this.currentUser.role !== ROLES.ADMIN) {
      const array = cloneDeep(this.columns);
      this.columns.splice(4, 1);
    }
  }
}
</script>
